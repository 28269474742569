<template>
    <div>
        <!--<calendar v-if="$vuetify.breakpoint.lgAndUp" :times="times" :user="user"></calendar>-->
        <!--<v-container v-else>-->
        <!--<DateTimeComponent @done="redirect"></DateTimeComponent>-->
        <!--</v-container>-->

        <template v-if="$store.getters.userHasRole('counselor')">

            <counselorCalendar></counselorCalendar>
        </template>
        <template v-else>
            <calendar v-if="$vuetify.breakpoint.lgAndUp"
                      :profile="profile"
                      @getProfile="updateProfile"
            ></calendar>
            <v-container v-else>
                <DateTimeComponent
                        @getProfile="updateProfile"
                        @done="redirect"
                        :profile="profile"
                ></DateTimeComponent>
            </v-container>
        </template>


    </div>
</template>

<script>
    /*eslint-disable*/
    import calendar from "../../components/NewCalendar";
    import counselorCalendar from "../../components/calendar/calendar";
    import DateTimeComponent from "../../components/DateTimeComponent";
    import {EventBus} from "@/event-bus";

    export default {
        components: {
            calendar, DateTimeComponent, counselorCalendar
        },
        data() {
            return {
                times: [],
                user: null,
                profile: this.$store.getters.getUserInfo

            }
        },
        methods: {
            redirect() {
                console.log('x');
            },
            updateProfile() {

                this.$store.dispatch('getUserInfo').then(() => {
                    this.profile = this.$store.getters.getUserInfo;
                });

                // this.$store.dispatch('getUserProfile', {
                //     userid: this.$router.currentRoute.params.clientId
                // }).then(() => {
                //     this.profile = this.$store.getters.getClientProfile;
                //     EventBus.$emit('clientProfileUpdated');
                // })
            }
        }
    }
</script>

<style scoped>

</style>


<!--<template>-->
<!--    <v-container fluid>-->
<!--        <v-snackbar-->
<!--                :color="snackbarType"-->
<!--                id="snackbar-calendar"-->
<!--                bottom-->
<!--                v-model="snackbar"-->
<!--                :timeout="timeout"-->
<!--        >-->
<!--            {{ text }}-->
<!--            <v-btn-->
<!--                    fab-->
<!--                    :color="snackbarType"-->
<!--                    @click="snackbar = false"-->
<!--            >-->
<!--                <v-icon>{{ iconType }}</v-icon>-->
<!--            </v-btn>-->
<!--        </v-snackbar>-->
<!--        <v-row>-->
<!--            <v-col cols="12" lg="9">-->
<!--                <v-card class="pa-2" min-height="563">-->
<!--                    <v-row>-->
<!--                        <v-col class="px-3">-->
<!--                            <v-btn fab dark small color="secondary" @click="prevWeek">-->
<!--                                <v-icon dark>mdi-arrow-right</v-icon>-->
<!--                            </v-btn>-->
<!--                        </v-col>-->
<!--                        <v-col  class="title" align="center">-->
<!--                            <span>انتخاب زمانهای آزاد مشاوره</span>-->
<!--                        </v-col>-->
<!--                        <v-col class="px-3" align="end" @click="nextWeek">-->
<!--                            <v-btn  fab dark small color="secondary" >-->
<!--                                <v-icon dark>mdi-arrow-left</v-icon>-->
<!--                            </v-btn>-->
<!--                        </v-col>-->
<!--                    </v-row>-->

<!--                    <v-row v-for="(week, index) in cloneDays" :key="`week-title-${index}`">-->
<!--                        <v-col class="pt-0" align="center" v-if="currentWeek == index+1">-->
<!--                            <span class="text&#45;&#45;secondary">{{ week.days[0].jdate + jfaToEnMonth(week.days[0].jmonth) }} تا {{ week.days[6].jdate + jfaToEnMonth(week.days[6].jmonth) }}</span>-->
<!--                        </v-col>-->
<!--                    </v-row>-->

<!--                    <v-row class="pa-1" v-for="(week, id) in cloneDays" :key="`week-${id}`">-->
<!--                        <v-col class="text-center pa-1" :cols="$vuetify.breakpoint.xsOnly ? 12 : ''" v-for="(weekDay, index) in week.days" :key="`week-${index}`" v-show="currentWeek == id+1">-->
<!--                            <v-card-->
<!--                                    outlined-->
<!--                            >-->
<!--                                <div>{{ weekDay.day }}</div>-->
<!--                                <div>{{ weekDay.jdate + ' ' + jfaToEnMonth(weekDay.jmonth) }}</div>-->
<!--                                <div>{{ weekDay.mmonth + ' ' + weekDay.mdate }}</div>-->
<!--                                <v-divider></v-divider>-->
<!--                                <v-card-->
<!--                                        :ripple="false"-->
<!--                                        class="elevation-0"-->
<!--                                        min-height="300"-->
<!--                                        @click="selectDate(weekDay)"-->
<!--                                        :ref="weekDay.fullDate"-->
<!--                                        :style= "[isSelected == weekDay.fullDate ? { 'backgroundColor': 'rgba(67,55,140,0.50)' } : {}]">-->
<!--                                    <v-row>-->
<!--                                        <v-col :cols="!$vuetify.breakpoint.xsOnly ? 12 : ''" class="pa-1" v-for="(time, index) in weekDay.timebooks" :key="`time-${index}`">-->
<!--                                            <v-hover v-slot:default="{ hover }">-->
<!--                                                <v-chip color="default" label v-ripple style="direction: ltr;">-->
<!--                                                    <v-icon :class="{'no-opacity':true,'chip-visible':hover} " color="red" small-->
<!--                                                            @click.self="removeTime(weekDay, time)"-->
<!--                                                            v-ripple>-->
<!--                                                        fas fa-times-->
<!--                                                    </v-icon>-->
<!--                                                    <span class="mx-1">{{ time }}</span>-->
<!--                                                </v-chip>-->
<!--                                            </v-hover>-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                </v-card>-->
<!--                            </v-card>-->
<!--                        </v-col>-->
<!--                    </v-row>-->

<!--                    <v-row v-show="$route.path == '/fa/panel/calendar'">-->
<!--                        <v-col>-->
<!--                            <v-btn color="#86C23B" dark class="elevation-0" @click="postConsultInfo">ثبت زمان‌ها</v-btn>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </v-card>-->
<!--            </v-col>-->
<!--            <v-col cols="12" lg="3" v-if="!isMobile">-->
<!--                <v-card class="pa-2" :class="{'heartbeat':heartbeat}">-->
<!--                    <v-card-title class="headline lighten-2" primary-title>-->
<!--                        <v-col cols="12" align="center" class="py-0">-->
<!--                            <h5>ِزمان محلی: {{ profile.tz }}</h5>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" align="center" class="py-0">-->
<!--                            <span class="title">ِ{{ fullDate }}</span>-->
<!--                        </v-col>-->
<!--                    </v-card-title>-->
<!--                    <v-divider></v-divider>-->
<!--                    <v-row  v-for="(timeSel, index) in timeSelArr" :key="`timeSel${index}`">-->
<!--                        <v-col align="center" class="px-0" cols="4" v-for="time in timeSel" :key="time">-->
<!--                            <v-chip color="secondary" dark label :outlined="selectedTimeInDate.includes(time) ? false : true" style="font-size: .8rem; direction: ltr" @click="selectTime(time)">{{ time }}</v-chip>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </v-card>-->
<!--            </v-col>-->

<!--            <div class="text-center" v-if="isMobile">-->
<!--                <v-dialog v-model="dialog" width="500">-->
<!--                    <v-card>-->
<!--                        <v-card-title class="headline grey lighten-2" primary-title>-->
<!--                            <v-col cols="12" align="center" class="py-0">-->
<!--                                <span class="subtitle-1">ِزمان محلی: {{ profile.tz }}</span>-->
<!--                            </v-col>-->
<!--                            <v-col cols="12" align="center" class="py-0">-->
<!--                                <span class="title">ِ{{ fullDate }}</span>-->
<!--                            </v-col>-->
<!--                        </v-card-title>-->

<!--                        <v-card class="" flat>-->
<!--                            <v-row  v-for="(timeSel, index) in timeSelArr" :key="`timeSel${index}`">-->
<!--                                <v-col cols="4" class="px-2" v-for="time in timeSel" :key="time">-->
<!--                                    <v-chip dark label color="secondary" style="font-size: .8rem; direction: ltr;" @click="selectTime(time)">{{ time }}</v-chip>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                        </v-card>-->

<!--                        <v-divider></v-divider>-->

<!--                        <v-card-actions>-->
<!--                            <v-btn text color="#86C23B" @click="dialog = false">تایید و بازگشت</v-btn>-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-btn text color="red" @click="dialog = false">بستن</v-btn>-->
<!--                        </v-card-actions>-->
<!--                    </v-card>-->
<!--                </v-dialog>-->
<!--            </div>-->
<!--        </v-row>-->
<!--    </v-container>-->
<!--</template>-->

<!--<script>-->
<!--    // import counselorCalendar from '@/components/dashboards/counselorCalendar.vue';-->
<!--    // import adminCalendar from '@/components/dashboards/adminCalendar.vue';-->
<!--    // import clientCalendar from '@/components/dashboards/clientCalendar.vue';-->
<!--    import {timezones} from '../../utilities';-->
<!--    import moment from 'moment-jalaali';-->
<!--    import {EventBus} from "../../event-bus";-->

<!--    export default {-->
<!--        components: {-->
<!--            // counselorCalendar,-->
<!--            // adminCalendar,-->
<!--            // clientCalendar-->
<!--        },-->
<!--        data() {-->
<!--            return {-->
<!--                type: 'success',-->
<!--                snackbar: false,-->
<!--                timeout: 2000,-->
<!--                isSuccess: false,-->
<!--                isSelected: false,-->
<!--                timezone: '',-->
<!--                timezones: [],-->
<!--                months: moment().months(),-->
<!--                today:moment().format("D"),-->
<!--                fullDate:moment().format("jYYYY/jMM/jDD"),-->
<!--                thisMonth:moment().format("jMMMM"),-->
<!--                thisYear:moment().format("YYYY"),-->
<!--                weekDays: moment.weekdays(),-->
<!--                weekNum: 2,-->
<!--                firstDay: parseInt(moment().startOf('month').format('D')),-->
<!--                lastDay: parseInt(moment().endOf('month').format('D')),-->
<!--                firstWeekDay: moment().startOf('month').format("dddd"),-->
<!--                local: this.$store.getters.getLocal,-->

<!--                selectedTimeTimezone: null,-->
<!--                selectedTimezone: "",-->
<!--                profile: null,-->
<!--                maxCal: "",-->
<!--                minCal: "",-->
<!--                days: [],-->
<!--                cloneDays: {},-->
<!--                currentWeek: 1,-->
<!--                timesChoosed: {},-->
<!--                timeSelArr: {-->
<!--                    "1": ["00:00 - 01:00", "01:00 - 02:00", "02:00 - 03:00"],-->
<!--                    "2": ["03:00 - 04:00", "04:00 - 05:00", "05:00 - 06:00"],-->
<!--                    "3": ["06:00 - 07:00", "07:00 - 08:00", "08:00 - 09:00"],-->
<!--                    "4": ["09:00 - 10:00", "10:00 - 11:00", "11:00 - 12:00"],-->
<!--                    "5": ["12:00 - 13:00", "13:00 - 14:00", "14:00 - 15:00"],-->
<!--                    "6": ["15:00 - 16:00", "16:00 - 17:00", "17:00 - 18:00"],-->
<!--                    "7": ["18:00 - 19:00", "19:00 - 20:00", "20:00 - 21:00"],-->
<!--                    "8": ["21:00 - 22:00", "22:00 - 23:00", "23:00 - 24:00"]-->
<!--                },-->
<!--                calSelectedDay: null,-->
<!--                isMobile: false,-->
<!--                dialog: false,-->
<!--                newTimebooks: [],-->
<!--                consultInfo: {-->
<!--                    timebooks: [],-->
<!--                    id: this.$route.params.id,-->
<!--                    tz: ""-->
<!--                },-->
<!--                selectedTimeInDate: [],-->
<!--                heartbeat: false,-->
<!--                heartBeatHandle: null,-->
<!--            }-->
<!--        },-->
<!--        mounted() {-->
<!--            this.timezones = timezones;-->
<!--            this.profile = this.$store.getters.getUserInfo;-->
<!--            this.selectedTimeTimezone = this.profile.tz;-->
<!--            this.selectedTimezone = this.profile.country;-->

<!--            this.consultInfo.timebooks = this.profile.timebooks;-->

<!--            if (this.profile.timebooks != null) {-->
<!--                this.fillCalendar(this.profile.timebooks);-->
<!--            }-->

<!--            if (document.body.scrollWidth < 500) {-->
<!--                this.isMobile = true;-->
<!--            }-->

<!--            if (this.calTimezone == "fa") {-->
<!--                this.minCal = moment().format("jYYYY/jMM/jDD");-->
<!--                this.maxCal = moment()-->
<!--                    .add(14, "days")-->
<!--                    .format("jYYYY/jMM/jDD");-->
<!--            } else if (this.calTimezone == "en") {-->
<!--                this.minCal = moment().format("YYYY/MM/DD");-->
<!--                this.maxCal = moment()-->
<!--                    .add(14, "days")-->
<!--                    .format("YYYY/MM/DD");-->
<!--            }-->
<!--        },-->
<!--        beforeDestroy() {-->
<!--            EventBus.$off('saveTimeEvent', this.postConsultInfo);-->
<!--        },-->
<!--        created() {-->
<!--            console.log(this.$route.path);-->

<!--            this.getDays();-->
<!--            let copyDays = [...this.days];-->
<!--            this.cloneDays = copyDays;-->

<!--            this.profile = this.$store.getters.getUserInfo;-->

<!--            console.log('profile', this.profile)-->

<!--            window.addEventListener('resize', this.handleResize);-->
<!--            this.handleResize();-->

<!--            EventBus.$on('saveTimeEvent', this.postConsultInfo);-->
<!--        },-->
<!--        destroyed() {-->
<!--            window.removeEventListener('resize', this.handleResize)-->
<!--        },-->
<!--        watch: {-->
<!--            selectedTimezone: function () {-->
<!--                this.getDays();-->
<!--                // this.dateSel();-->
<!--            }-->
<!--        },-->
<!--        computed: {-->
<!--            calTimezone: function () {-->
<!--                switch (this.selectedTimezone) {-->
<!--                    case "IR":-->
<!--                        return "fa";-->
<!--                    default:-->
<!--                        return "en";-->
<!--                }-->
<!--            },-->
<!--            text() {-->
<!--                switch (this.type) {-->
<!--                    case "success":-->
<!--                        return this.$vuetify.lang.t('$vuetify.saveSuccessfullyMsg');-->
<!--                    case "warning":-->
<!--                        return this.$vuetify.lang.t('$vuetify.warningSelectDateMsg');-->
<!--                    case "error":-->
<!--                        return this.$vuetify.lang.t('$vuetify.saveErrorMsg');-->
<!--                    default:-->
<!--                        return "";-->
<!--                }-->
<!--            },-->
<!--            snackbarType() {-->
<!--                switch (this.type) {-->
<!--                    case "success":-->
<!--                        return 'success';-->
<!--                    case "warning":-->
<!--                        return 'warning';-->
<!--                    case "error":-->
<!--                        return 'error';-->
<!--                    default:-->
<!--                        return "";-->
<!--                }-->
<!--            },-->
<!--            iconType() {-->
<!--                switch (this.type) {-->
<!--                    case "success":-->
<!--                        return 'mdi-check';-->
<!--                    case "warning":-->
<!--                        return 'mdi-exclamation';-->
<!--                    case "error":-->
<!--                        return 'mdi-close';-->
<!--                    default:-->
<!--                        return "";-->
<!--                }-->
<!--            }-->
<!--        },-->
<!--        methods: {-->
<!--            handleResize() {-->
<!--                if(window.innerWidth < 500) {-->
<!--                    this.isMobile = true;-->
<!--                }-->
<!--            },-->
<!--            save() {-->
<!--                console.log(this.timezone);-->
<!--            },-->
<!--            userHasRole(role) {-->
<!--                return this.$store.getters.userHasRole(role);-->
<!--            },-->
<!--            removeTime(weekDay,time) {-->
<!--                this.selectDate(weekDay);-->
<!--                this.selectTime(time);-->
<!--                this.$forceUpdate();-->
<!--            },-->
<!--            faToEnMonth(inp) {-->
<!--                switch (inp) {-->
<!--                    case "ژانویه":-->
<!--                        return "January";-->
<!--                    case "فوریه":-->
<!--                        return "February";-->
<!--                    case "مارس":-->
<!--                        return "March";-->
<!--                    case "آوریل":-->
<!--                        return "April";-->
<!--                    case "مه":-->
<!--                        return "May";-->
<!--                    case "ژوئن":-->
<!--                        return "June";-->
<!--                    case "ژوئیه":-->
<!--                        return "July";-->
<!--                    case "اوت":-->
<!--                        return "Auguest";-->
<!--                    case "سپتامبر":-->
<!--                        return "September";-->
<!--                    case "اکتبر":-->
<!--                        return "October";-->
<!--                    case "نوامبر":-->
<!--                        return "November";-->
<!--                    case "دسامبر":-->
<!--                        return "December";-->
<!--                    default:-->
<!--                        return inp;-->
<!--                    }-->
<!--                },-->
<!--                jfaToEnMonth(inp) {-->
<!--                    switch (inp) {-->
<!--                        case "Farvardin":-->
<!--                            return "فروردین";-->
<!--                        case "Ordibehesht":-->
<!--                            return "اردیبهشت";-->
<!--                        case "Khordad":-->
<!--                            return "خرداد";-->
<!--                        case "Tir":-->
<!--                            return "تیر";-->
<!--                        case "Mordad":-->
<!--                            return "مرداد";-->
<!--                        case "ُShahrivar":-->
<!--                            return "شهریور";-->
<!--                        case "Mehr":-->
<!--                            return "مهر";-->
<!--                        case "Aban":-->
<!--                            return "آبان";-->
<!--                        case "Azar":-->
<!--                            return "آذر";-->
<!--                        case "Dey":-->
<!--                            return "دی";-->
<!--                        case "Bahman":-->
<!--                            return "filk";-->
<!--                        case "Esfand":-->
<!--                            return "اسفند";-->
<!--                        default:-->
<!--                            return inp;-->
<!--                    }-->
<!--            },-->
<!--            prevWeek() {-->
<!--                if (this.currentWeek > 1) {-->
<!--                    this.currentWeek&#45;&#45;;-->
<!--                    console.log(this.currentWeek);-->
<!--                    this.calSelectedDay = null;-->
<!--                    this.selectedTimeInDate = [];-->
<!--                    this.isSelected = false;-->
<!--                }-->
<!--            },-->
<!--            nextWeek() {-->
<!--                if (this.currentWeek < this.weekNum) {-->
<!--                    this.currentWeek++;-->
<!--                    console.log(this.currentWeek);-->
<!--                    this.calSelectedDay = null;-->
<!--                    this.selectedTimeInDate = [];-->
<!--                    this.isSelected = false;-->
<!--                }-->
<!--            },-->
<!--            getDays() {-->
<!--                // moment.loadPersian({-->
<!--                //     dialect: "persian-modern"-->
<!--                // });-->
<!--                let maxDays = this.weekNum * 7;-->
<!--                let days = [{-->
<!--                    days: [],-->
<!--                    name: ""-->
<!--                }];-->
<!--                let j = 0;-->
<!--                for(let i=0; i < maxDays; i++) {-->
<!--                    let thisMoment = moment().add(i, 'days');-->
<!--                    if(this.selectedTimezone == "IR") {-->
<!--                        days[j].days.push({-->
<!--                            fullDate: thisMoment.format("jYYYY/jMM/jDD"),-->
<!--                            year: thisMoment.format("jYYYY"),-->
<!--                            jmonth: thisMoment.format("jMMMM"),-->
<!--                            mfamonth: thisMoment.format("MMMM"),-->
<!--                            mmonth: this.faToEnMonth(thisMoment.format("MMMM")),-->
<!--                            day: thisMoment.format("dddd"),-->
<!--                            jdate: thisMoment.format("jDD"),-->
<!--                            mdate: thisMoment.format("DD"),-->
<!--                            id: i + 1-->
<!--                        })-->
<!--                    }else {-->
<!--                        days[j].days.push({-->
<!--                            fullDate: thisMoment.format("YYYY/MM/DD"),-->
<!--                            year: thisMoment.format("YYYY"),-->
<!--                            mmonth: thisMoment.format("MMMM"),-->
<!--                            jmonth: thisMoment.format("jMMMM"),-->
<!--                            day: thisMoment.format("dddd"),-->
<!--                            mdate: thisMoment.format("DD"),-->
<!--                            jdate: thisMoment.format("jDD"),-->
<!--                            id: i + 1-->
<!--                        });-->
<!--                    }-->
<!--                    if (i % 7 == 6) {-->
<!--                        if (i != maxDays - 1) {-->
<!--                            j++;-->
<!--                            days[j] = {-->
<!--                                days: [],-->
<!--                                name: ""-->
<!--                            };-->
<!--                        }-->
<!--                    }-->

<!--                    this.days = days;-->
<!--                }-->
<!--                return this.days;-->
<!--            },-->
<!--            fillCalendar(times) {-->
<!--                // GET TIME BOOKS FROM PROFILE-->
<!--                let out = {};-->
<!--                times.forEach(item => {-->
<!--                    let newKey = moment(item.date, "YYYY/MM/DD").format("YYYY/MM/DD");-->
<!--                    out[newKey] = [];-->
<!--                    item.periods.forEach(function (period) {-->
<!--                        var end = moment(period.end, 'HH').format('HH:mm');-->
<!--                        if (end == "00:00") {-->
<!--                            end = "24:00";-->
<!--                        }-->
<!--                        out[newKey].push(moment(period.start, 'HH').format('HH:mm') + ' - ' + end);-->
<!--                    });-->
<!--                });-->
<!--                this.timesChoosed = out;-->

<!--                //FIND DATES IN 2 WEEKS-->
<!--                this.days.forEach(week => {-->
<!--                    week.days.forEach(day => {-->
<!--                        day.timebooks = [];-->
<!--                        if(Object.keys(this.timesChoosed).includes(day.fullDate)) {-->
<!--                            day.timebooks = [...this.timesChoosed[day.fullDate]];-->
<!--                        }-->
<!--                    })-->
<!--                });-->
<!--                this.cloneDays = [...this.days];-->
<!--            },-->
<!--            selectDate(input) {-->
<!--                this.isSelected = input.fullDate;-->
<!--                this.calSelectedDay = input;-->
<!--                this.dialog = true;-->
<!--                !input.timebooks ? input['timebooks'] = [] :-->
<!--                input.timebooks.forEach(time => {-->
<!--                    console.log('TIME', time);-->
<!--                    for(let key in this.timeSelArr) {-->
<!--                        console.log(this.timeSelArr[key].indexOf(time));-->
<!--                    }-->
<!--                });-->
<!--                this.selectedTimeInDate = input.timebooks;-->

<!--                if (!this.heartbeat) {-->
<!--                    this.heartbeat = true;-->
<!--                    this.heartBeatHandle = setTimeout(() => {-->
<!--                        this.heartbeat = false;-->
<!--                    }, 1000);-->
<!--                } else {-->
<!--                    clearTimeout(this.heartBeatHandle);-->
<!--                    this.heartBeatHandle = setTimeout(() => {-->
<!--                        this.heartbeat = false;-->
<!--                    }, 1000);-->

<!--                }-->
<!--            },-->
<!--            selectTime(input) {-->
<!--                if(this.calSelectedDay == null) {-->
<!--                    this.type = 'warning';-->
<!--                    this.snackbar = true;-->
<!--                    console.log('empty');-->
<!--                }else {-->
<!--                    console.log('input', input);-->
<!--                    const Day = this.calSelectedDay.id - 1;-->
<!--                    const timebooks = this.cloneDays[this.currentWeek - 1].days[Day <= 6 ? Day : Day - 7].timebooks;-->
<!--                    let index = timebooks.indexOf(input);-->
<!--                    (index >= 0) ? timebooks.splice(index, 1) : timebooks.push(input);-->

<!--                    //PREPARE DATA FOR POST-->
<!--                    this.newTimebooks = [];-->
<!--                    this.cloneDays.forEach(week => {-->
<!--                        week.days.forEach(day => {-->
<!--                            if(!day.timebooks) {-->
<!--                                day['timebooks'] = []-->
<!--                            }-->
<!--                            if (day.timebooks.length != 0) {-->
<!--                                let start = "";-->
<!--                                let end = "";-->
<!--                                this.newTimebooks.push({'date': day.fullDate, 'periods': []});-->
<!--                                day.timebooks.forEach(time => {-->
<!--                                    start = time.split(" - ")[0];-->
<!--                                    end = time.split(" - ")[1];-->
<!--                                    this.newTimebooks.forEach(item => {-->
<!--                                        if (item.date == day.fullDate) {-->
<!--                                            item.periods.push({'start': start, 'end': end})-->
<!--                                        }-->
<!--                                    })-->
<!--                                })-->
<!--                            }-->
<!--                        });-->
<!--                    })-->
<!--                    this.consultInfo.timebooks = this.newTimebooks;-->
<!--                    console.log('new timebooks after select time: ', this.consultInfo.timebooks);-->
<!--                    this.$forceUpdate();-->
<!--                }-->
<!--            },-->
<!--            postConsultInfo() {-->
<!--                this.profile.timebooks = this.consultInfo.timebooks;-->
<!--                this.$store.dispatch('postConsultInfo', this.consultInfo).then((resp) => {-->

<!--                    if (resp.data.message == 'Success') {-->
<!--                        this.profile.state.requireFillTimebooks = false;-->
<!--                        let data = {};-->
<!--                        data.state = this.profile.state;-->
<!--                        data.role = this.profile.role;-->
<!--                        this.$store.commit('setUserInfo', this.profile);-->
<!--                        this.type = 'success';-->
<!--                        this.snackbar = true;-->
<!--                        // this.$store.dispatch('checkState', data).then((nextRoute) => {-->
<!--                        //     this.$router.push(nextRoute);-->
<!--                        // })-->
<!--                    }-->
<!--                })-->
<!--                    .catch(err => {-->
<!--                        console.log(err)-->
<!--                        this.msgColor = "red";-->
<!--                        this.message = "خطایی پیش آمده، دوباره امتحان کنید";-->
<!--                        this.notifyVue(this.message, "warning");-->
<!--                    });-->

<!--            }-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style>-->
<!--    /*#snackbar-calendar .v-snack__wrapper {*/-->
<!--    /*    background-color: #fff;*/-->
<!--    /*    border-right: 5px solid #86C23B;*/-->
<!--    /*    color: #888;*/-->
<!--    /*}*/-->
<!--    /*#snackbar-calendar .v-snack__wrapper {*/-->
<!--    /*    background-color: #fff;*/-->
<!--    /*    border-right: 5px solid red;*/-->
<!--    /*    color: red;*/-->
<!--    /*}*/-->
<!--</style>-->

<!--<style scoped>-->
<!--    .no-opacity {-->
<!--        opacity: 0;-->
<!--        width: 0;-->
<!--        transition: all .2s ease-in-out;-->
<!--    }-->

<!--    .chip-visible {-->
<!--        width: 15px;-->
<!--        opacity: 1;-->
<!--    }-->

<!--    @keyframes heartbeat {-->
<!--        0% {-->
<!--            -webkit-box-shadow: none;-->
<!--            -moz-box-shadow: none;-->
<!--            box-shadow: none;-->
<!--        }-->
<!--        50% {-->
<!--            -webkit-box-shadow: 0 0 15px rgba(67,55,140, .8);-->
<!--            -moz-box-shadow: 0 0 15px rgba(67,55,140, .8);-->
<!--            box-shadow: 0 0 15px rgba(67,55,140, .8);-->
<!--        }-->
<!--        100% {-->
<!--            -webkit-box-shadow: none;-->
<!--            -moz-box-shadow: none;-->
<!--            box-shadow: none;-->
<!--        }-->
<!--    }-->
<!--    .heartbeat {-->
<!--        animation-duration: 1s;-->
<!--        animation-name: heartbeat;-->
<!--        animation-iteration-count: infinite;-->
<!--    }-->

<!--</style>-->

